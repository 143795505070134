<template>
  <div
    v-if="isVisible"
    :class="className"
  >
    <div class="loader">
      <Icon
        :colorValue="colorValue"
        :isEmbed="isEmbed"
      />
    </div>
  </div>
</template>

<script>
import iconLoader from '../../assets/icons/icon-loader.svg';
import Icon from './Icon.vue';

export default {
  name: 'Loader',
  components: { Icon },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    colorValue: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'inspector-loader',
    },
  },
  data() {
    return {
      iconLoader,
    };
  },
};
</script>

<style scoped>
  .inspector-loader {
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .inspector-loader .loader {
    animation: loader-animate 1.5s linear infinite;
    height: 80px;
    left: calc(50% - 40px);
    position: absolute;
    top: calc(50% - 40px);
    width: 80px;
  }

  @keyframes loader-animate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
