<template>
  <div>
    <Bitmovin
      v-if="isShowPlayer"
      :content="playerContent"
      :uniqueId="uniqueId"
      :isEmbed="isEmbed"
      :autoplay="autoplay"
      :debug="debug"
      :onClick="onClick"
      :onEventFinished="onEventFinished"
      :colorValue="colorValue"
      :lang="lang"
    />
    <Countdown
      v-else
      :backgroundUrl="image"
      :eventText="eventText"
      :timeBeforeStart="timeBeforeStart"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import * as workerTimers from 'worker-timers';
import Countdown from './Countdown.vue';
import Bitmovin from '../player/Bitmovin.vue';

export default {
  name: 'EventGroup',
  components: {
    Countdown,
    Bitmovin,
  },
  props: {
    uniqueId: {
      type: String,
      default: null,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    allowed: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    colorValue: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    updatePlayerSource: {
      type: Function,
      default: () => {},
    },
    lang: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShowPlayer: false,
      startCounterId: null,
      timeBeforeStart: null,
      LivePreview: 'https://images.broadcastcloud.tv/broadcastcloud/static/live-preview.jpg',
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.common.content[0],
      currentStandaloneEvent: (state) => state.common.content[0].currentStandaloneEvent,
    }),
    ...mapGetters([
      'isGroup',
      'isEvents',
    ]),
    playerContent() {
      return [this.currentStandaloneEvent];
    },
    getTimeBeforeStart() {
      if (!this.currentStandaloneEvent) {
        return null;
      }
      const start = moment.utc(this.currentStandaloneEvent.startDate);
      const now = moment.utc();

      return (start.diff(now) > 0) ? moment(start.diff(now)).unix() : null;
    },
    getTimeBeforeFinish() {
      if (!this.currentStandaloneEvent) {
        return null;
      }
      const end = moment.utc(this.currentStandaloneEvent.finishDate);
      const now = moment.utc();

      return (end.diff(now) > 0) ? moment(end.diff(now)).unix() : null;
    },
    eventText() {
      return this.currentStandaloneEvent ? this.currentStandaloneEvent.name : '';
    },
    image() {
      if (this.content && this.content.backgroundUrl) {
        return this.content.backgroundUrl;
      }
      if (this.content
        && !this.content.backgroundUrl
        && this.content.currentStandaloneEvent
        && this.content.currentStandaloneEvent.backgroundUrl) {
        return this.content.currentStandaloneEvent.backgroundUrl;
      }
      return this.LivePreview;
    },
  },
  watch: {
    timeBeforeStart(newValue, oldValue) {
      if (newValue <= 0 && newValue !== oldValue) {
        if (this.startCounterId) {
          this.startCounterId = workerTimers.clearInterval(this.startCounterId);
        }
        this.updatePlayerSource();
      }
    },
  },
  created() {
    window.addEventListener('focus', this.setFocusOnTab);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.setFocusOnTab);
  },
  methods: {
    init() {
      if (this.getTimeBeforeStart > 0) {
        this.setCountdownCounter();
        return;
      }
      if (!this.getTimeBeforeStart && this.getTimeBeforeFinish > 0) {
        this.isShowPlayer = true;
      }
    },
    setCountdownCounter() {
      this.timeBeforeStart = this.getTimeBeforeStart;
      this.startCounterId = workerTimers.setInterval(() => {
        if (this.timeBeforeStart >= 0) {
          this.timeBeforeStart -= 1;
        }
      }, 1000);
    },
    onEventFinished() {
      this.updatePlayerSource();
    },
    setFocusOnTab() {
      this.$forceUpdate();
    },
  },
};
</script>
