import {
  GET_PLAYER_CONTENT,
  GET_PLAYER_CONTENT_REQUEST,
  GET_PLAYER_CONTENT_SUCCESS,
  GET_PLAYER_CONTENT_ERROR,
  GET_PLAYER_CONTENT_LEGACY,
  GET_PLAYER_CONTENT_LEGACY_REQUEST,
  GET_PLAYER_CONTENT_LEGACY_SUCCESS,
  GET_PLAYER_CONTENT_LEGACY_ERROR,
} from '../../actions/common';
import apiCall from '../../../utils/api/api';

const state = {
  isLoading: true,
  content: [],
  poster: null,
  allowedDomains: [],
  forbiddenDomains: [],
  domainRestrictionEnabled: true,
  playerId: 'oxagile-dev',
  type: null,
};

const getters = {
  isEvents: () => state.type === 'StandaloneEvent' || state.type === 'StandaloneEventGroup',
  isGroup: () => state.type === 'StandaloneEventGroup',
  isStandalone: () => state.type === 'StandaloneEvent',
};

const actions = {
  [GET_PLAYER_CONTENT]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_PLAYER_CONTENT_REQUEST);

    let url = `sdk/content/${params.uniqueId}`;
    if (params.userId) {
      url = `${url}?userId=${params.userId}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_PLAYER_CONTENT_SUCCESS, resp.data);

        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_PLAYER_CONTENT_ERROR);
        reject(error);
      });
  }),
  [GET_PLAYER_CONTENT_LEGACY]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_PLAYER_CONTENT_LEGACY_REQUEST);

    let url = `sdk/content/legacy/${params.accountId}?legacyId=${params.legacyId}`;
    if (params.userId) {
      url = `${url}&userId=${params.userId}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_PLAYER_CONTENT_LEGACY_SUCCESS, resp.data);

        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_PLAYER_CONTENT_LEGACY_ERROR);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_PLAYER_CONTENT_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PLAYER_CONTENT_LEGACY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PLAYER_CONTENT_SUCCESS]: (stateLocal, payload) => {
    state.isLoading = false;
    state.poster = payload.poster;
    state.allowedDomains = payload.allowedDomains;
    state.forbiddenDomains = payload.forbiddenDomains;
    state.domainRestrictionEnabled = payload.domainRestrictionEnabled;
    state.playerId = payload.playerId;
    state.type = payload.type;
    state.content = payload.content;
  },
  [GET_PLAYER_CONTENT_LEGACY_SUCCESS]: (stateLocal, payload) => {
    state.isLoading = false;
    state.poster = payload.poster;
    state.allowedDomains = payload.allowedDomains;
    state.forbiddenDomains = payload.forbiddenDomains;
    state.domainRestrictionEnabled = payload.domainRestrictionEnabled;
    state.playerId = payload.playerId;
    state.type = payload.type;
    state.content = payload.content;
  },
  [GET_PLAYER_CONTENT_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PLAYER_CONTENT_LEGACY_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
