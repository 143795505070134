<template>
  <div>
    <Loader
      :isVisible="isLoading"
      :className="'inspector-loader'"
      :colorValue="colorValue"
      :isEmbed="isEmbed"
    />
    <div v-if="isAllowed">
      <button
        v-if="isMutedAutoplay"
        class="unmute-button"
        @click="handleUnmute"
      >
        <img
          :src="iconMute"
          class="svg-icon"
          alt="mute"
        />
        Click/tap to unmute
      </button>
      <div
        ref="player"
        class="bitmovinplayer-container aspect-16x9"
        :class="[contentId === 'audio' ? 'audio' : '']"
        @click.prevent="onClick"
      >
      </div>
    </div>
    <div
      v-else
      class="widget-error"
    >
      <div class="widget-error-text">
        {{ playerNoContentMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import youbora from 'youboralib';
import YouboraBitmovin8Adapter from 'youbora-adapter-bitmovin8';
import {
  UIManager,
  SubtitleOverlay,
  SettingsPanel,
  SettingsPanelPage,
  SettingsPanelItem,
  VideoQualitySelectBox,
  PlaybackSpeedSelectBox,
  AudioQualitySelectBox,
  SubtitleSettingsPanelPage,
  SettingsPanelPageOpenButton,
  ControlBar,
  Container,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  SeekBar,
  SeekBarLabel,
  PlaybackToggleButton,
  VolumeToggleButton,
  VolumeSlider,
  Spacer,
  PictureInPictureToggleButton,
  AirPlayToggleButton,
  CastToggleButton,
  VRToggleButton,
  SettingsToggleButton,
  FullscreenToggleButton,
  UIContainer,
  BufferingOverlay,
  PlaybackToggleOverlay,
  CastStatusOverlay,
  TitleBar,
  RecommendationOverlay,
  Watermark,
  ErrorMessageOverlay,
  SubtitleSelectBox,
  AudioTrackSelectBox,
} from 'bitmovin-player/bitmovinplayer-ui';
import {
  Player,
  TimelineReferencePoint,
  PlayerEvent,
  WarningCode,
  ErrorCode,
} from 'bitmovin-player/modules/bitmovinplayer-core';

import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesVTTModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-vtt';
import SubtitlesNativeModuleModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-native';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import ContainerWebMModule from 'bitmovin-player/modules/bitmovinplayer-container-webm';
import RemoteControlModule from 'bitmovin-player/modules/bitmovinplayer-remotecontrol';
import Thumbnail from 'bitmovin-player/modules/bitmovinplayer-thumbnail';
import SubtitlesModuleCEA from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608';
import Loader from '../common/Loader.vue';
import CONFIG from '../../constants/config';
import Logger from '../../utils/logger/logger';
import iconMute from '../../assets/icons/icon-mute.svg';

Player.addModule(EngineNativeModule);
Player.addModule(EngineBitmovinModule);
Player.addModule(MseRendererModule);
Player.addModule(HlsModule);
Player.addModule(AbrModule);
Player.addModule(ContainerTSModule);
Player.addModule(ContainerMp4Module);
Player.addModule(XmlModule);
Player.addModule(DashModule);
Player.addModule(ContainerWebMModule);
Player.addModule(SubtitlesModule);
Player.addModule(SubtitlesNativeModuleModule);
Player.addModule(SubtitlesVTTModule);
Player.addModule(PolyfillModule);
Player.addModule(StyleModule);
Player.addModule(RemoteControlModule);
Player.addModule(Thumbnail);
Player.addModule(SubtitlesModuleCEA);

export default {
  name: 'Bitmovin',
  components: {
    Loader,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    uniqueId: {
      type: String,
      default: null,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    colorValue: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: '',
    },
    allowed: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onEventFinished: {
      type: Function,
      default: () => {},
    },
    isPlayPostEventAction: {
      type: Boolean,
      default: false,
    },
    onFinishPlayPostEventAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      color: '',
      isDestroy: false,
      isMutedAutoplay: false,
      autoplayMuteIssuer: 'AUTOPLAY_MUTE_ISSUER',
      isFirstPlay: true,
      maxErrorCount: 3,
      consecutiveErrors: [],
      restarted: false,
      isLoading: false,
      logger: new Logger(this.debug),
      messages: CONFIG.messages,
      playerNoContentMessage: CONFIG.messages.playerNoContentMessage,
      isAllowed: this.allowed,
      playlistCounter: 0,
      player: null,
      postEventActionPlayCounter: 0,
      playerSource: null,
      playerConfig: {
        key: CONFIG.player.bitmovinKey,
        ui: false,
        remotecontrol: {
          type: 'googlecast',
          customReceiverConfig: {
            receiverStylesheetUrl: 'https://images.broadcastcloud.tv/broadcastcloud/chromecast/receiverStylesheetUrl.css',
          },
        },
        playback: {
          autoplay: this.autoplay,
        },
        events: {
          [PlayerEvent.PlaybackFinished]: this.loadNextVideo,
          [PlayerEvent.Error]: this.checkHasError,
          [PlayerEvent.DownloadFinished]: this.finishDownload,
          [PlayerEvent.Ready]: this.checkReady,
          [PlayerEvent.Warning]: this.checkWarningEvent,
          [PlayerEvent.Muted]: this.updateMutedAutoplay,
          [PlayerEvent.Unmuted]: this.updateUnmuteAutoplay,
        },
      },
      analyticOptions: {},
      iconMute,
      contentId: null,
    };
  },
  computed: {
    ...mapState({
      poster: (state) => state.common.poster,
      forbiddenDomains: (state) => state.common.forbiddenDomains,
      playerId: (state) => state.common.playerId,
    }),
    ...mapGetters([
      'isEvents',
      'isStandalone',
    ]),
  },
  mounted() {
    this.setupPlayer();
    document.querySelector('button.bmpui-ui-watermark').style.display = 'none';

    if (!this.isEmbed) {
      if (this.$route.query.color) {
        this.color = this.$route.query.color;
      }
    } else if (this.colorValue) {
      this.color = this.colorValue;
    }

    this.toggleColorClass('green', '.bmpui-seekbar-playbackposition');
    this.toggleColorClass('green', '.bmpui-seekbar-playbackposition-marker');
    this.toggleColorSettingsClass('green', '.bmpui-ui-selectbox');
    this.toggleColorIconsClass('green', '.bmpui-ui-settingstogglebutton bmpui-on');
    this.toggleColorSelectClass('green', '.bmpui-ui-selectbox option:checked');
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
  methods: {
    toggleColorClass(colorClassName, elementClass) {
      const allElements = document.querySelectorAll(elementClass);
      allElements.forEach((element) => {
        element.style.cssText = `background-color: #${this.color}; border: #${this.color}`;
      });
    },
    toggleColorSettingsClass(colorClassName, elementClass) {
      const allElements = document.querySelectorAll(elementClass);
      allElements.forEach((element) => {
        element.style.cssText = `color: #${this.color}`;
      });
    },
    toggleColorSelectClass(colorClassName, elementClass) {
      const allElements = document.querySelectorAll(elementClass);
      allElements.forEach((element) => {
        element.style.cssText = `color: #${this.color}`;
      });
    },
    toggleColorIconsClass(colorClassName, elementClass) {
      const allElements = document.querySelectorAll(elementClass);
      allElements.forEach((element) => {
        element.style.cssText = `background-image: drop-shadow(0 0 1px #${this.color})`;
      });
    },
    getPlayerSource(content, index, poster) {
      const {
        playerSource,
        analyticOptions,
        type,
      } = content[index];

      this.analyticOptions = analyticOptions;
      this.contentId = type;

      playerSource.options = {
        startOffset: 0,
        startOffsetTimelineReference: TimelineReferencePoint.End,
      };

      if (poster) {
        playerSource.poster = poster;
      }

      return playerSource;
    },
    checkHasError(event) {
      if (this.isEvents && event.code === ErrorCode.SOURCE_COULD_NOT_LOAD_MANIFEST) {
        this.onEventFinished();
      } else {
        this.checkHasErrorHandler();
      }
    },
    checkHasErrorHandler() {
      if (this.isDestroy || this.isAllPlaylistInvalid()) {
        this.logger.addError(this.messages.loadError);
      } else if (!this.isErrorLimitReached(this.playlistCounter)) {
        this.loadNextVideo();
        this.restarted = true;
      } else {
        this.restarted = false;
        this.playlistCounter += 1;
        this.loadNextVideo();
      }
    },
    finishDownload(data) {
      const item = this.playlistCounter;
      if (!data.success) {
        this.consecutiveErrors[item] = this.getErrorCounter(item) + 1;
      } else {
        this.consecutiveErrors[item] = 0;
      }
    },
    checkReady() {
      if (this.restarted) {
        this.loadNextVideo();
        this.restarted = false;
      }
    },
    checkWarningEvent(event) {
      if (event.code === WarningCode.PLAYBACK_COULD_NOT_BE_STARTED
        && this.autoplay
        && !this.isMutedAutoplay
      ) {
        this.isMutedAutoplay = true;
        this.player.mute(this.autoplayMuteIssuer);
        this.player.play();
      }
    },
    handleUnmute() {
      this.player.unmute();
    },
    updateUnmuteAutoplay() {
      this.isMutedAutoplay = false;
    },
    updateMutedAutoplay(event) {
      if (event.issuer !== this.autoplayMuteIssuer && this.isMutedAutoplay) {
        this.isMutedAutoplay = false;
      }
    },
    setupPlayer() {
      this.isLoading = true;
      this.player = new Player(this.$refs.player, this.playerConfig);

      this.player.on('subtitleadded', () => {
        const subs = Array.from(this.player.subtitles.list());

        if (this.lang) {
          const currentSub = subs.find((item) => item.lang === this.lang);
          if (currentSub) {
            this.player.subtitles.enable(currentSub.id);
          }
        } else if (localStorage.getItem('currentSub')) {
          const currentSub = subs.find((item) => item.lang === JSON.parse(localStorage.getItem('currentSub')).lang);
          if (currentSub) {
            this.player.subtitles.enable(currentSub.id);
          }
        }
      });

      this.player.on('subtitleenabled', (e) => {
        localStorage.setItem('currentSub', JSON.stringify(e.subtitle));
      });

      this.player.on('audiochanged', (e) => {
        localStorage.setItem('currentTrack', JSON.stringify(e.targetAudio));
      });

      // eslint-disable-next-line no-unused-vars
      const uiManager = new UIManager(this.player, this.createUIContainer());
      this.loadNextVideo();
    },
    destroyPlayer() {
      this.isDestroy = true;

      if (this.player) {
        this.player.destroy();
      }
    },
    loadNextVideo() {
      if (this.isStandalone && this.isPlayPostEventAction && this.postEventActionPlayCounter > 0) {
        this.onFinishPlayPostEventAction();
      } else if (this.content && this.content.length) {
        if (!this.content[this.playlistCounter] && this.playlistCounter) {
          this.playlistCounter = 0;
          this.isFirstPlay = false;
        }

        this.isLoading = true;
        const playerSource = this.getPlayerSource(this.content, this.playlistCounter, this.poster);
        this.playerSource = playerSource;

        this.player.unload()
          .then(() => {
            this.initYouboraAnalytics();

            this.player.load(playerSource)
              .then(() => {
                const tracks = this.player.getAvailableAudio();
                console.log(tracks, 'tracks');
                if (this.lang) {
                  const currentTrack = tracks.find((item) => item.lang === this.lang);
                  if (currentTrack) {
                    this.player.setAudio(currentTrack.id);
                  }
                } else if (localStorage.getItem('currentTrack')) {
                  const currentTrack = tracks.find((item) => item.lang === JSON.parse(localStorage.getItem('currentTrack')).lang);
                  if (currentTrack) {
                    this.player.setAudio(currentTrack.id);
                  }
                }

                if (this.isMutedAutoplay) {
                  this.player.unmute(this.autoplayMuteIssuer);
                  this.isMutedAutoplay = false;
                }

                if (this.playlistCounter > 0
              && (!this.isFirstPlayableItem() || (this.autoplay && this.isFirstPlay))
                ) {
                  this.player.play();
                }
                this.playlistCounter += 1;
                this.postEventActionPlayCounter += 1;
              })
              .catch((err) => {
                console.log(err);
                this.logger.addError(this.messages.loadError);
                throw err;
              })
              .finally(() => {
                this.isLoading = false;
              });
          });
      } else {
        this.isAllowed = false;
        this.isLoading = false;
        this.logger.addInfo(this.messages.noContent);
      }
    },
    initYouboraAnalytics() {
      if (window.plugin) {
        window.plugin.disable();
      }

      if (this.analyticOptions && this.analyticOptions.accountCode) {
        window.plugin = new youbora.Plugin(
          this.analyticOptions,
          new YouboraBitmovin8Adapter(this.player),
        );
      } else {
        window.plugin = null;
      }
    },
    getErrorCounter(item) {
      if (this.consecutiveErrors[item]) {
        return this.consecutiveErrors[item];
      }

      return 0;
    },
    isFirstPlayableItem() {
      if (this.content && this.content.length) {
        for (let i = 0; i < this.content.length && i < this.playlistCounter; i += 1) {
          if (!this.isErrorLimitReached(i)) {
            return false;
          }
        }
      }

      return true;
    },
    isAllPlaylistInvalid() {
      if (this.content && this.content.length) {
        for (let i = 0; i < this.content.length; i += 1) {
          if (!this.isErrorLimitReached(i)) {
            return false;
          }
        }
      }

      return true;
    },
    isErrorLimitReached(item) {
      return this.getErrorCounter(item) >= this.maxErrorCount;
    },
    createUIContainer() {
      let counter = 0;
      if (this.content && this.content.length === 1) {
        counter = 0;
      } else {
        counter = this.playlistCounter;
      }

      const hasSubs = this.content
        && this.content[counter]
        && this.content[counter].hasCompletedSubtitles;

      const subtitleOverlay = new SubtitleOverlay();

      const settingsPanel = new SettingsPanel({
        components: [
          new SettingsPanelPage({
            components: [
              new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
              new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox()),
              new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
            ],
          }),
        ],
        hidden: true,
      });

      const subtitleListBoxSettingsPanelPage = new SettingsPanelPage({
        components: [
          new SettingsPanelItem('Subtitles', new SubtitleSelectBox()),
        ],
        hidden: true,
      });

      const subtitleSettingsPanel = new SettingsPanel({
        components: [
          subtitleListBoxSettingsPanelPage,
        ],
        hidden: true,
        pageTransitionAnimation: true,
      });

      const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
        settingsPanel: subtitleSettingsPanel,
        overlay: subtitleOverlay,
      });

      const subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
        targetPage: subtitleSettingsPanelPage,
        container: subtitleSettingsPanel,
        text: 'Settings',
        cssClasses: ['customization-open-button'],
      });

      subtitleListBoxSettingsPanelPage.addComponent(
        new SettingsPanelItem('Settings', subtitleSettingsOpenButton, {
          cssClasses: ['subtitle-customization-settings-panel-item'],
          hidden: !hasSubs,
        }),
      );
      subtitleSettingsPanel.addComponent(subtitleSettingsPanelPage);

      const audioTrackSettingsPanel = new SettingsPanel({
        components: [
          new SettingsPanelPage({
            components: [
              new SettingsPanelItem('Audio Tracks', new AudioTrackSelectBox()),
            ],
          }),
        ],
        hidden: true,
      });

      const controlBar = new ControlBar({
        components: [
          audioTrackSettingsPanel,
          subtitleSettingsPanel,
          settingsPanel,
          new Container({
            components: [
              new PlaybackTimeLabel(
                { timeLabelMode: PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true },
              ),
              new SeekBar({ label: new SeekBarLabel() }),
              new PlaybackTimeLabel({ timeLabelMode: PlaybackTimeLabelMode.TotalTime, cssClasses: ['text-right'] }),
            ],
            cssClasses: ['controlbar-top'],
          }),
          new Container({
            components: [
              new PlaybackToggleButton(),
              new VolumeToggleButton(),
              new VolumeSlider(),
              new Spacer(),
              new PictureInPictureToggleButton(),
              new AirPlayToggleButton(),
              new CastToggleButton(),
              new VRToggleButton(),
              new SettingsToggleButton({
                settingsPanel: audioTrackSettingsPanel,
                cssClass: 'ui-audiotracksettingstogglebutton',
              }),
              new SettingsToggleButton({
                settingsPanel: subtitleSettingsPanel,
                cssClass: 'ui-subtitlesettingstogglebutton',
              }),
              new SettingsToggleButton({ settingsPanel }),
              new FullscreenToggleButton(),
            ],
            cssClasses: ['controlbar-bottom'],
          }),
        ],
      });

      return new UIContainer({
        components: [
          subtitleOverlay,
          new BufferingOverlay(),
          new PlaybackToggleOverlay(),
          new CastStatusOverlay(),
          controlBar,
          new TitleBar(),
          new RecommendationOverlay(),
          new Watermark(),
          new ErrorMessageOverlay(),
        ],
      });
    },
  },
};
</script>
<style scoped>
  .unmute-button {
    z-index: 10;
    position: absolute;
    top: 30px;
    left: 30px;
    background: #fff;
    font-size: 14px;
    padding: 10px 15px;
    border: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .unmute-button:hover {
    background: rgba(255,255,255,0.8);
    filter: drop-shadow(0 0 2px #fff);
  }

  .unmute-button:focus {
    outline: none;
  }

  .unmute-button .svg-icon {
    margin-right: 10px;
  }

  .widget-error {
    align-items: center;
    background-color: #ccc;
    display: flex;
    height: 540px;
    justify-content: center;
  }

  .audio >>> .bitmovinplayer-poster {
    display: block !important;
  }

  .widget-error-text {
    color: #000;
    font-size: 1.2em;
    left: 3em;
    right: 3em;
    text-align: center;
  }

  .bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton {
    background-color: transparent;
    box-shadow: none;
  }

  /*.widget-container >>>*/
  /*.bmpui-ui-hugeplaybacktogglebutton:focus {*/
  /*  box-shadow: none !important;*/
  /*  outline: none !important;*/
  /*}*/

  .widget-container >>>
  .bmpui-ui-settings-panel-page {
    width: 200px !important;
  }

  .widget-container >>>
  .bmpui-container-wrapper>.bmpui-ui-selectbox {
    margin-left: 0 !important;
    width: 50% !important;
  }
</style>

<style>
  [id^="bmpui-id"][class^="bmpui-ui"],
  [id^="bmpui-id"][class^="bmpui-ui"]:focus,
  .bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .bmpui-ui-watermark {
    display: none;
  }

  button[class="bmpui-ui-watermark"] {
    display: none;
  }

  .bmpui-ui-settings-panel {
    width: 300px !important;
  }

  .bmpui-container-wrapper >.bmpui-ui-selectbox {
    margin-left: 10px !important;
    width: 50% !important;
  }
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');

.bmpui-subtitle-region-container {
  position: absolute !important;
  padding: 10px !important;
  bottom: 1em !important;
  left: 3em !important;
  right: 3em !important;
  top: auto !important;
}

.bmpui-ui-subtitle-label {
  display: inline-block !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  background: rgba(0, 0, 0, 0.6) !important;
  outline: none !important;
  padding: 10px !important;
  width: auto !important;
  position: static !important;
  bottom: 2em !important;
}

.bmpui-ui-settingspanelpageopenbutton {
  padding-left: 12px !important;
}
</style>

<style lang="scss">
.bmpui-ui-selectbox option:checked {
  color: var(--color) !important;
}

.bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none;
}
</style>
