const CONFIG = {
  player: {
    bitmovinKey: 'ec1c9dc8-9a07-4ed1-964a-245a962cec13',
  },
  statuses: {
    failedStatus: 500,
    badRequest: 400,
    notFound: 404,
  },
  messages: {
    restrictedDomain: 'Domain "{domain}" is not allowed.',
    loadError: 'Source cannot be loaded.',
    unsupportedPlayerType: 'Player Type "{type}" is not supported.',
    noContent: 'Content not available.',
    backendError: 'Error: {message}',
    playerNoContentMessage: 'This content is not currently available',
  },
};

export default CONFIG;
