class Logger {
  debug = false;

  constructor(debug) {
    this.debug = debug;
  }

  addInfo(message, params) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.info(this.constructor.generateMessage(message, params));
    }
  }

  addWarning(message, params) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.warn(this.constructor.generateMessage(message, params));
    }
  }

  addError(message, params) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.error(this.constructor.generateMessage(message, params));
    }
  }

  static generateMessage(message, params) {
    if (message instanceof String) {
      let string = String(message);

      if (params instanceof Object) {
        Object.entries(params).forEach(([key, value]) => {
          string = string.replace(key, value);
        });
      }

      return string;
    }

    return message;
  }
}

export default Logger;
