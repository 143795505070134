import Vue from 'vue';
import media from './views/Media.vue';
import store from './store/store';
import Logger from './utils/logger/logger';
import CONFIG from './constants/config';

import '@babel/polyfill';

Vue.config.productionTip = false;

const attrPrefix = 'data-ikonik-player-';

const requiredAttrs = {
  playerType: {
    key: `${attrPrefix}type`,
    type: String,
  },
};

const optionalAttrs = {
  autoplay: {
    key: `${attrPrefix}autoplay`,
    type: Boolean,
  },
  debug: {
    key: `${attrPrefix}debug`,
    type: Boolean,
  },
  legacyId: {
    key: `${attrPrefix}legacyId`,
    type: String,
  },
  color: {
    key: `${attrPrefix}color`,
    type: String,
  },
  accountId: {
    key: `${attrPrefix}accountId`,
    type: String,
  },
  uniqueId: {
    key: `${attrPrefix}id`,
    type: String,
  },
  userId: {
    key: `${attrPrefix}userId`,
    type: String,
  },
  lang: {
    key: `${attrPrefix}lang`,
    type: String,
  },
};

const attrs = [];

Object.values(requiredAttrs).forEach((value) => {
  attrs.push(`[${value.key}]`);
});

const widgets = {
  default: media,
};

const blocks = document.querySelectorAll(attrs.join(''));
const getAttribute = (value, type) => {
  if (type === Boolean) {
    return value === 'true';
  }
  return value;
};

blocks.forEach((block) => {
  const props = {
    isEmbed: true,
  };

  Object.entries(optionalAttrs).forEach(([key, value]) => {
    props[key] = getAttribute(block.getAttribute(value.key), value.type);
  });
  Object.entries(requiredAttrs).forEach(([key, value]) => {
    props[key] = getAttribute(block.getAttribute(value.key), value.type);
  });

  const widget = widgets[props.playerType];

  if (widget) {
    new Vue({
      store,
      render: (h) => h(widget, {
        props,
      }),
    }).$mount(block);
  } else {
    const logger = new Logger(props.debug);

    logger.addWarning(CONFIG.messages.unsupportedPlayerType, {
      '{type}': props.playerType,
    });
  }
});
