<template>
  <div>
    <Bitmovin
      v-if="isShowPlayer"
      :content="content"
      :uniqueId="uniqueId"
      :isEmbed="isEmbed"
      :autoplay="autoplay"
      :debug="debug"
      :onClick="onClick"
      :isPlayPostEventAction="isPlayPostEventAction"
      :onFinishPlayPostEventAction="onFinishPlayPostEventAction"
      :onEventFinished="onEventFinished"
      :colorValue="colorValue"
      :lang="lang"
    />
    <Countdown
      v-else
      :backgroundUrl="content[0] && content[0].backgroundUrl
        ? content[0].backgroundUrl
        : LivePreview"
      :eventText="eventText"
      :timeBeforeStart="timeBeforeStart"
      :isPostEventActionBtn="isPostEventActionBtn"
      :onPlayPostEventAction="onPlayPostEventAction"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import * as workerTimers from 'worker-timers';
import Countdown from './Countdown.vue';
import Bitmovin from '../player/Bitmovin.vue';

export default {
  name: 'Event',
  components: {
    Countdown,
    Bitmovin,
  },
  props: {
    uniqueId: {
      type: String,
      default: null,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    allowed: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    colorValue: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    updatePlayerSource: {
      type: Function,
      default: () => {},
    },
    lang: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startCounterId: null,
      timeBeforeStart: null,
      isShowPlayer: false,
      isPostEventActionBtn: false,
      isPlayPostEventAction: false,
      LivePreview: 'https://images.broadcastcloud.tv/broadcastcloud/static/live-preview.jpg',
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.common.content,
      startDate: (state) => state.common.content[0].startDate,
      finishDate: (state) => state.common.content[0].finishDate,
    }),
    ...mapGetters([
      'isGroup',
      'isEvents',
      'isStandalone',
    ]),
    getTimeBeforeStart() {
      const start = moment.utc(this.startDate);
      const now = moment.utc();

      return (start.diff(now) > 0) ? moment(start.diff(now)).unix() : null;
    },
    getTimeBeforeFinish() {
      const end = moment.utc(this.finishDate);
      const now = moment.utc();

      return (end.diff(now) > 0) ? moment(end.diff(now)).unix() : null;
    },
    isEventCompleted() {
      return !this.getTimeBeforeStart && !this.getTimeBeforeFinish;
    },
    eventText() {
      if (this.isEventCompleted) {
        return this.content[0].postEventMessage;
      }
      return this.content[0].name;
    },
  },
  watch: {
    timeBeforeStart(newValue, oldValue) {
      if (newValue <= 0 && newValue !== oldValue) {
        if (this.startCounterId) {
          this.startCounterId = workerTimers.clearInterval(this.startCounterId);
        }
        this.updatePlayerSource();
      }
    },
  },
  created() {
    window.addEventListener('focus', this.setFocusOnTab);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.setFocusOnTab);
  },
  methods: {
    init() {
      if (this.getTimeBeforeStart > 0) {
        this.setCountdownCounter();
        return;
      }
      if (!this.getTimeBeforeStart && this.getTimeBeforeFinish > 0) {
        this.isShowPlayer = true;
        return;
      }
      if (this.isEventCompleted) {
        this.isPostEventActionBtn = this.content[0].hasPostEventPresentVideo;
      }
    },
    setCountdownCounter() {
      this.timeBeforeStart = this.getTimeBeforeStart;
      this.startCounterId = workerTimers.setInterval(() => {
        if (this.timeBeforeStart >= 0) {
          this.timeBeforeStart -= 1;
        }
      }, 1000);
    },
    onEventFinished() {
      this.updatePlayerSource();
    },
    onPlayPostEventAction() {
      this.isShowPlayer = true;
      this.isPlayPostEventAction = true;
    },
    onFinishPlayPostEventAction() {
      this.isShowPlayer = false;
      this.isPlayPostEventAction = false;
    },
    setFocusOnTab() {
      this.$forceUpdate();
    },
  },
};
</script>
