<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.0001 14.8387C37.8066 14.8387 36.1292 13.1613 36.1292
      10.9677V3.87097C36.1292 1.67742 37.8066
       0 40.0001 0C42.1937 0 43.8711 1.67742 43.8711
       3.87097V10.9677C43.8711 13.0323 42.1937 14.8387 40.0001 14.8387Z"
      :fill="fill"
    />
    <path
      d="M40.0001 80C37.8066 80 36.1292 78.3225 36.1292
       76.129V69.0322C36.1292 66.8387 37.8066 65.1613
      40.0001 65.1613C42.1937 65.1613 43.8711 66.8387
       43.8711 69.0322V76.129C43.8711 78.3225 42.1937 80 40.0001 80Z"
      :fill="fill"
      fill-opacity="0.6"
    />
    <path
      d="M25.4195 18.7096C24.1292 18.7096 22.8388 18.0644
      22.0646 16.7741L18.5808 10.7096C17.5485 8.90315
      18.1937 6.45154 20.0001 5.41928C21.8066 4.38702
       24.2582 5.03218 25.2905 6.83863L28.7743 12.9031C29.8066
       14.7096 29.1614 17.1612 27.355 18.1935C26.8388
        18.4515 26.0646 18.7096 25.4195 18.7096Z"
      :fill="fill"
    />
    <path
      d="M58.0647 75.0968C56.7744 75.0968 55.4841
       74.4516 54.7099 73.1613L51.226 67.0968C50.1938 65.2904
       50.8389 62.8387 52.6454 61.8065C54.4518 60.7742
        56.9035 61.4194 57.9357 63.2258L61.4196 69.2904C62.4518
        71.0968 61.8067 73.5484 60.0002 74.5807C59.3551
         74.9678 58.7099 75.0968 58.0647 75.0968Z"
      :fill="fill"
      fill-opacity="0.8"
    />
    <path
      d="M14.8388 29.2903C14.1936 29.2903 13.5484 29.1613
       12.9033 28.7742L6.83876 25.2903C5.0323 24.2581
      4.38714 21.8065 5.4194 20C6.45166 18.1936 8.90327
      17.5484 10.7097 18.5807L16.7742 22.0645C18.5807 23.0968
       19.2259 25.5484 18.1936 27.3548C17.4194 28.6452
       16.1291 29.2903 14.8388 29.2903Z"
      :fill="fill"
    />
    <path
      d="M71.355 61.9355C70.7098 61.9355 70.0647 61.8064
       69.4195 61.4193L63.226 57.9355C61.4195 56.9032
       60.7744 54.4516 61.8066 52.6451C62.8389 50.8387
       65.2905 50.1935 67.0969 51.2258L73.1614 54.7097C74.9679
        55.7419 75.613 58.1935 74.5808 60C73.9356 61.2903
         72.6453 61.9355 71.355 61.9355Z"
      :fill="fill"
    />
    <path
      d="M10.9677 43.871H3.87097C1.67742 43.871 0 42.1935
       0 40C0 37.8064 1.67742 36.129 3.87097 36.129H10.9677C13.1613
      36.129 14.8387 37.8064 14.8387 40C14.8387 42.1935
      13.0323 43.871 10.9677 43.871Z"
      :fill="fill"
      fill-opacity="0.05"
    />
    <path
      d="M76.1289 43.871H69.0321C66.8386 43.871 65.1611
      42.1935 65.1611 40C65.1611 37.8064 66.8386 36.129 69.0321
      36.129H76.1289C78.3224 36.129 79.9998 37.8064 79.9998
       40C79.9998 42.1935 78.3224 43.871 76.1289 43.871Z"
      :fill="fill"
    />
    <path
      d="M8.64509 61.9355C7.35477 61.9355 6.06444 61.2903
       5.29025 60C4.25799 58.1935 4.90315 55.7419 6.70961
      54.7097L12.7741 51.2258C14.5806 50.1935 17.0322
      50.8387 18.0644 52.6451C19.0967 54.4516 18.4515 56.9032
       16.6451 57.9355L10.5806 61.4193C10.0644 61.8064
        9.41928 61.9355 8.64509 61.9355Z"
      :fill="fill"
      fill-opacity="0.2"
    />
    <path
      d="M65.1614 29.2903C63.8711 29.2903 62.5808 28.6452
       61.8066 27.3548C60.7744 25.5484 61.4195 23.0968 63.226
       22.0645L69.2905 18.5807C71.0969 17.5484 73.5485
       18.1936 74.5808 20C75.613 21.8065 74.9679 24.2581 73.1614
        25.2903L67.0969 28.7742C66.4518 29.1613 65.8066
         29.2903 65.1614 29.2903Z"
      :fill="fill"
    />
    <path
      d="M21.9356 75.0968C21.2905 75.0968 20.6453 74.9678
       20.0001 74.5807C18.1937 73.5484 17.5485 71.0968 18.5808
       69.2904L22.0646 63.2258C23.0969 61.4194 25.5485
       60.7742 27.355 61.8065C29.1614 62.8387 29.8066 65.2904 28.7743
       67.0968L25.2905 73.1613C24.5163 74.4516 23.2259
        75.0968 21.9356 75.0968Z"
      :fill="fill"
      fill-opacity="0.4"
    />
    <path
      d="M54.5809 18.7096C53.9357 18.7096 53.2906 18.5806
      52.6454 18.1935C50.8389 17.1612 50.1938 14.7096 51.226
      12.9031L54.7099 6.83863C55.7422 5.03218 58.1938
       4.38702 60.0002 5.41928C61.8067 6.45154 62.4518 8.90315
      61.4196 10.7096L57.9357 16.7741C57.1615 17.9354
      55.8712 18.7096 54.5809 18.7096Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    colorValue: {
      type: String,
      default: '',
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fill() {
      if (!this.isEmbed) {
        if (this.$route
        && this.$route.query
        && this.$route.query.color && this.$route.query.color) {
          return `#${this.$route.query.color}`;
        }
        return '#2591CF';
      }
      if (this.colorValue) {
        return `#${this.colorValue}`;
      }
      return '#2591CF';
    },
  },
};
</script>

<style scoped>

</style>
