class DomainValidator {
  domain = this.constructor.getDomain();

  isDomain(domains) {
    if (domains instanceof Array && domains.length) {
      return domains.some((domain) => this.constructor.validateDomain(this.domain, domain));
    }

    return false;
  }

  static validateDomain(currentDomain, validDomain) {
    const parts = validDomain.split('*');
    let item = currentDomain;
    let isValid = true;

    let isPreviousAsterisk = parts[0] === '';
    const isLastAsterisk = parts[parts.length - 1] === '';

    for (let i = 0; i < parts.length; i += 1) {
      const part = parts[i];
      if (part) {
        const start = item.indexOf(part);
        const end = part.length;

        if (start !== -1) {
          if (
            (isPreviousAsterisk && start !== 0 && !item.substring(0, start).includes('.'))
            || (!isPreviousAsterisk && start === 0)
          ) {
            item = item.substring(start + end);
          } else {
            isValid = false;
            break;
          }
        } else {
          isValid = false;
          break;
        }

        isPreviousAsterisk = true;
      }
    }

    if (item.length && !isLastAsterisk) {
      isValid = false;
    }

    return isValid;
  }

  static getDomain() {
    if (window.frameElement) {
      return window.parent.location.hostname;
    }

    return window.location.hostname;
  }

  static getDefaultDomain() {
    return process.env.VUE_APP_FRONTEND_HOST;
  }
}

export default DomainValidator;
