<template>
  <div :class="['widget-container', { 'direct' : direct }]">
    <Loader
      v-if="isLoaded"
      :isVisible="true"
      :className="'inspector-loader'"
    />
    <div v-else>
      <div v-if="isEvents && isAllowed">
        <EventGroup
          v-if="isGroup"
          :updatePlayerSource="updatePlayerSource"
          :uniqueId="uniqueId"
          :isEmbed="isEmbed"
          :autoplay="autoplay"
          :debug="debug"
          :direct="direct"
          :allowed="isAllowed"
          :colorValue="color"
          :lang="lang"
        />
        <Event
          v-else
          :updatePlayerSource="updatePlayerSource"
          :uniqueId="uniqueId"
          :isEmbed="isEmbed"
          :autoplay="autoplay"
          :debug="debug"
          :direct="direct"
          :allowed="isAllowed"
          :colorValue="color"
          :lang="lang"
        />
      </div>
      <Bitmovin
        v-else
        :content="content"
        :uniqueId="uniqueId"
        :isEmbed="isEmbed"
        :autoplay="autoplay"
        :debug="debug"
        :direct="direct"
        :onClick="onClick"
        :allowed="isAllowed"
        :colorValue="color"
        :lang="lang"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { GET_PLAYER_CONTENT, GET_PLAYER_CONTENT_LEGACY } from '../store/actions/common';
import Event from '../components/events/Event.vue';
import EventGroup from '../components/events/EventGroup.vue';
import Bitmovin from '../components/player/Bitmovin.vue';
import DomainValidator from '../utils/validation/domain';
import Logger from '../utils/logger/logger';
import Loader from '../components/common/Loader.vue';

import CONFIG from '../constants/config';

export default {
  name: 'Media',
  components: {
    Event,
    EventGroup,
    Bitmovin,
    Loader,
  },
  props: {
    uniqueId: {
      type: String,
      default: null,
    },
    legacyId: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    lang: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    accountId: {
      type: String,
      default: null,
    },
    playerType: {
      type: String,
      default: null,
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    direct: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isAllowed: true,
      logger: new Logger(this.debug),
      messages: CONFIG.messages,
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.common.content,
      allowedDomains: (state) => {
        const domains = [
          DomainValidator.getDefaultDomain(),
        ];

        if (state.common.allowedDomains instanceof Array) {
          return domains.concat(state.common.allowedDomains);
        }

        return domains;
      },
      domainRestrictionEnabled: (state) => state.common.domainRestrictionEnabled,
      isLoaded: (state) => state.common.isLoading,
    }),
    ...mapGetters([
      'isEvents',
      'isGroup',
    ]),
  },
  created() {
    if (this.direct) {
      document.body.className = 'direct';
    }
  },
  mounted() {
    this.updatePlayerSource();
  },
  methods: {
    updatePlayerSource() {
      if (this.legacyId) {
        const params = {
          accountId: this.accountId,
          legacyId: !this.playerType
            ? encodeURIComponent(this.legacyId)
            : decodeURI(this.legacyId),
        };

        if (this.userId) {
          params.userId = this.userId;
        }

        this.$store.dispatch(GET_PLAYER_CONTENT_LEGACY, params)
          .then(() => {
            if (!this.domainRestrictionEnabled || this.validateDomains()) {
              this.isAllowed = true;
            } else {
              this.isAllowed = false;
            }
          }).catch((err) => {
            this.isAllowed = false;
            this.logger.addError(err);
          });
      } else {
        const params = {
          uniqueId: this.uniqueId,
        };

        if (this.userId) {
          params.userId = this.userId;
        }

        this.$store.dispatch(GET_PLAYER_CONTENT, params)
          .then(() => {
            if (!this.domainRestrictionEnabled || this.validateDomains()) {
              this.isAllowed = true;
            } else {
              this.isAllowed = false;
            }
          }).catch((err) => {
            this.isAllowed = false;
            this.logger.addError(err);
          });
      }
    },
    validateDomains() {
      const validator = new DomainValidator();
      const isAllowed = validator.isDomain(this.allowedDomains)
        && !validator.isDomain(this.forbiddenDomains);

      if (!isAllowed) {
        this.logger.addWarning(this.messages.restrictedDomain, {
          '{domain}': DomainValidator.getDomain(),
        });
      }

      return isAllowed;
    },
  },
};
</script>
<style scoped>
  .widget-container {
    margin: 0 auto;
    overflow: auto;
    position: relative;
  }
  .direct {
    max-height: 1000px !important;
    min-height: 540px;
    overflow-y: hidden !important;
    max-width: 960px;
    margin-top: 10px;
  }
</style>
