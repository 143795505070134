<template>
  <div class="poster">
    <div class="overlay-text">
      <button
        v-if="isPostEventActionBtn"
        class="btn-play"
        :title="'play'"
        @click="onPlayPostEventAction"
      >
        <img
          :src="iconPlay"
          class="svg-icon"
        />
      </button>
      <div
        v-if="eventText"
        class="ev-title-block"
        :title="eventText"
      >
        <small
          v-if="timeBeforeStart"
          class="next-up"
        >
          Next Up:
        </small>
        <span class="ev-title">
          {{ getName(eventText) }}
        </span>
      </div>
      <p
        v-if="timeBeforeStart"
        class="timer"
      >
        {{ getCountdown(timeBeforeStart) }}
      </p>
    </div>
    <img
      :ref="'preview'"
      :src="backgroundUrl"
      :alt="''"
    />
  </div>
</template>

<script>
import moment from 'moment';
import iconPlay from '../../assets/icons/icon-play-circle.svg';

export default {
  name: 'Countdown',
  props: {
    backgroundUrl: {
      type: String,
      default: '',
    },
    eventText: {
      type: String,
      default: '',
    },
    timeBeforeStart: {
      type: Number,
      default: null,
    },
    isPostEventActionBtn: {
      type: Boolean,
      default: false,
    },
    onPlayPostEventAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconPlay,
    };
  },
  methods: {
    getCountdown(timeBeforeStart) {
      const t = moment.duration(timeBeforeStart, 'seconds');
      const secs = t.seconds();
      const mins = t.minutes();
      const hrs = t.hours();
      const days = Math.floor(t.asDays());

      let str = '';
      str += `${days} ${days === 1 ? 'day ' : 'days '}`;
      str += `${hrs} ${hrs === 1 ? 'hour ' : 'hours '}`;
      str += `${mins} ${mins === 1 ? 'minute ' : 'minutes '}`;
      str += `${secs} ${secs === 1 ? 'second' : 'seconds'}`;

      return str;
    },
    getName(name) {
      return name.length > 83 ? `${name.slice(0, 83)}...` : name;
    },
  },
};
</script>

<style scoped>
  .poster {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
  }

  .overlay-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 30px;
    text-align: center;
  }

  .overlay-text p {
    margin: 20px;
  }

  .overlay-text small {
    font-size: 20px;
  }

  .btn-play {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .svg-icon {
    width: 90px;
  }

  .btn-play:hover .svg-icon {
    transform: scale(1.1);
  }

  .ev-title {
    width: 90%;
    display: block;
    word-wrap: break-word;
  }

  .ev-title-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  @media all and (max-width: 900px) {
    .timer {
      font-size: 16px;
    }

    .ev-title {
      width: 90%;
      display: block;
      word-wrap: break-word;
      font-size: 16px;
    }

    .overlay-text small {
      font-size: 16px;
    }
  }

  @media all and (max-width: 500px) {
    .timer {
      font-size: 14px;
    }

    .ev-title {
      font-size: 14px;
      display: block;
      width: 330px;
      word-wrap: break-word;
    }

    .overlay-text small {
      font-size: 14px;
    }
  }
</style>
